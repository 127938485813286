<template>
    <div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn btn-outline-avon  mr-2" data-toggle="modal" data-target="#exampleModal" v-if="permission">
           <i class="fas fa-history"></i> Historial
        </button>

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"> <i class="fas fa-history text-info"></i> Historial de versiones de aplicaciones</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                       <div class="row">
                           <div class="col">
                               <div class="card-title font-weight-bold mb-3"> <i class="nav-icon fab fa-windows text-primary"></i> Aplicación de mantenimiento de pagos </div>
                               <div class="table-responsive">
                                   <table class="table table-sm table-striped">
                                       <thead>
                                       <tr>
                                           <th scope="col">Version</th>
                                           <th scope="col">Fecha</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       <tr v-if="loadingMdp">
                                           <td colspan="3" class="text-center">
                                               <div class="spinner-border spinner-border-sm text-primary" role="status">
                                                   <span class="sr-only">Cargando...</span>
                                               </div>
                                               Cargando
                                           </td>
                                       </tr>
                                       <tr v-for="item in appMdp" :key="item.id">
                                           <td><a :href="item.url" v-text="item.version"  class="text-avon"></a></td>
                                           <td>
                                               <time v-text="item.upload_at" data-toggle="tooltip" data-placement="top" :title="item.readable_upload_at"></time>
                                           </td>
                                       </tr>
                                       <tr v-show="appMdp.length === 0">
                                           <td colspan="3" class="text-center">
                                               <span class="text-danger text-uppercase">No se han encontrado registros</span>
                                           </td>
                                       </tr>
                                       </tbody>
                                   </table>
                                   <pagination
                                       v-if="paginationAppMdp.last_page > 1"
                                       :pagination="paginationAppMdp"
                                       :offset="5"
                                       @paginate="fetchDataAppMdp()"
                                   ></pagination>
                               </div>
                           </div>
                           <div class="col">
                               <div class="card-title font-weight-bold mb-3"> <i class="nav-icon fab fa-android text-success"></i> Aplicación de LDC </div>
                             <div class="table-responsive">
                                 <table class="table table-sm table-striped">
                                     <thead>
                                     <tr>
                                         <th scope="col">Version</th>
                                         <th scope="col">Fecha</th>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     <tr v-if="loadingLdc">
                                         <td colspan="3" class="text-center">
                                             <div class="spinner-border spinner-border-sm text-primary" role="status">
                                                 <span class="sr-only">Cargando...</span>
                                             </div>
                                             Cargando
                                         </td>
                                     </tr>
                                     <tr v-for="item in appLdc" :key="item.id">
                                         <td><a :href="item.url" v-text="item.version" class="text-avon"></a></td>
                                         <td>
                                             <time v-text="item.upload_at" data-toggle="tooltip" data-placement="top" :title="item.readable_upload_at"></time>
                                         </td>
                                     </tr>
                                     <tr v-show="appLdc.length === 0">
                                         <td colspan="3" class="text-center">
                                             <span class="text-danger text-uppercase">No se han encontrado registros</span>
                                         </td>
                                     </tr>
                                     </tbody>
                                 </table>
                                 <pagination
                                     v-if="paginationAppLdc.last_page > 1"
                                     :pagination="paginationAppLdc"
                                     :offset="5"
                                     @paginate="fetchDataAppLdc()"
                                 ></pagination>
                             </div>
                           </div>
                       </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-avon" @click="refresh">
                            Actualizar
                        </button>
                        <button type="button" class="btn btn-outline-avon" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "HistoryVersionsApplications",
    props:['route','permission'],
    data(){
        return{
            loadingMdp : false,
            loadingLdc : false,
            appMdp : {},
            appLdc: {},
            paginationAppMdp:{
                current_page:1,
            },
            paginationAppLdc:{
                current_page:1,
            }
        }
    },
    methods:{
        refresh(){
            if (this.permission){
                this.paginationAppMdp.current_page =1
                this.paginationAppLdc.current_page =1
                this.fetchDataAppMdp()
                this.fetchDataAppLdc()
            }
        },
       fetchDataAppMdp(){
            this.loadingMdp=true;
            let route = `${this.route}?page=${this.paginationAppMdp.current_page}`

            axios.get(route,{
                params:{
                    type: 'appMdp'
                }

            })
                .then((response)=>{
                    this.appMdp = response.data.data;
                    this.paginationAppMdp = response.data.meta;
                    this.loadingMdp=false;
                })
                .catch((error)=>{
                    console.log('error');
                    this.loadingMdp=false;
                })
        },
        fetchDataAppLdc(){
            this.loadingLdc=true;
            let route = `${this.route}?page=${this.paginationAppLdc.current_page}`

            axios.get(route,{
                params:{
                    type: 'appLdc'
                }
            })
                .then((response)=>{
                    this.appLdc = response.data.data;
                    this.paginationAppLdc = response.data.meta;
                    this.loadingLdc=false;
                })
                .catch((error)=>{
                    console.log('error');
                    this.loadingLdc=false;
                })
        },
    },
    mounted() {

        if (this.permission){
            this.fetchDataAppMdp()
            this.fetchDataAppLdc()
        }

    },
    created() {
        this.$eventBus.$on('register-successful',this.refresh)
    }
}
</script>

<style scoped>

</style>
