<template>
   <div>
       <div class="row" v-if="showSuccessAlert">
           <div class="col">
               <div class="alert alert-primary" role="alert" v-html="message"></div>
           </div>
       </div>
       <div class="row">
           <div class="col d-flex justify-content-end">
               <slot></slot>
               <button type="button" @click="toggleModal" class="btn btn-avon"  v-if="permission">
                   <i class="fas fa-upload"></i> Actualizar aplicación
               </button>
           </div>
       </div>

       <div class="modal fade" id="formModal" data-backdrop="static" tabindex="-1" aria-labelledby="formModalLabel" aria-hidden="true">
           <div class="modal-dialog">
               <div class="modal-content">
                   <div class="modal-header">
                       <h5 class="modal-title" id="formModalLabel">
                           Actualizar aplicación
                       </h5>
                       <button type="button" class="close" @click="toggleModal">
                           <span aria-hidden="true">&times;</span>
                       </button>
                   </div>
                   <div class="modal-body">
                       <form>
                           <div class="alert alert-danger" role="alert"  v-if="serverError">
                               <strong  v-text="serverError"></strong>
                           </div>
                           <div class="form-group">
                               <label class="col-form-label">Aplicación a actualizar:</label>
                               <select class="custom-select" v-model="form.application_type"  :class="{'is-invalid':errors.get('application_type')}">
                                   <option :value="null">Escoger aplicación...</option>
                                   <option :value="application.id" v-text="application.name" v-for="application in applications" :key="application.id"></option>
                               </select>
                               <div class="invalid-feedback">
                                   {{errors.get('application_type')}}
                               </div>
                           </div>
                           <div class="form-group">
                               <label  class="col-form-label">Versión:</label>
                               <input type="text" class="form-control" v-model="form.version"
                                      :class="{'is-invalid':errors.get('version')}"
                                      placeholder="Agregar la versión de la aplicación">
                               <div class="invalid-feedback">
                                   {{errors.get('version')}}
                               </div>
                           </div>
                           <div class="form-group">
                               <label  class="col-form-label">Archivo adjunto:</label>
                               <vue-dropzone ref="myVueDropzone" id="dropzone"
                                             @vdropzone-error="errorUploading"
                                             @vdropzone-max-files-exceeded="filesExceeded"
                                             @vdropzone-file-added="change"
                                             :options="dropzoneOptions"
                                             :useCustomSlot=true
                                             >
                                   <div class="dropzone-custom-content">
                                       <h5 class="dropzone-custom-title">¡Arrastra y suelta para subir contenido!</h5>
                                       <div class="subtitle">...o haga clic para seleccionar un archivo de su dispositivo</div>
                                   </div>
                               </vue-dropzone>
                               <small class="text-danger">{{errors.get('file')}}</small>
                               <small class="text-danger" v-if="errorsMore" v-text="errorsMore"></small>
                           </div>
                       </form>
                   </div>
                   <div class="modal-footer">
                       <button type="button" class="btn btn-outline-avon" @click="clearFiles" v-if="showClear" :disabled="sending">Eliminar archivos</button>
                       <button type="button" class="btn btn-outline-avon" @click="toggleModal" :disabled="sending">Cancelar</button>
                       <button type="button" class="btn btn-avon" @click="updateForm" :disabled="sending">
                           <span v-if="!sending">Actualizar</span> <span v-else>Actualizando</span>
                           <div class="spinner-border spinner-border-sm text-white" role="status" v-if="sending">
                               <span class="sr-only">Cargando...</span>
                           </div>
                       </button>

                   </div>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
class Errors{
    constructor() {
        this.errors ={};
    }
    get(field){
        if (this.errors[field]){
            return this.errors[field][0];
        }
    }
    record(errors){
        this.errors = errors.errors;
    }
    clear(){
        this.errors ={};
    }
}
export default {
    name: "UpdateApplicationForm",
    props:['applications','mode','route','csrf_token','permission'],
    components: {
        vueDropzone: vue2Dropzone
    },
    data(){
        return{
            serverError: null,
            errorsMore:null,
            sending:false,
            showSuccessAlert:false,
            showClear:false,
            message:false,
            form:{
                application_type: null,
                version: '',
                environment: this.mode,
            },
            errors:new Errors(),
            dropzoneOptions: {
                url: this.route,
                createImageThumbnails:true,
                autoProcessQueue: false,
                thumbnailWidth: 150,
                maxFilesize: 1000,
                maxFiles: 1,
                headers: {
                    'X-Requested-With':'XMLHttpRequest',
                    'Access-Control-Allow-Headers' : 'Authorization, Content-Type, Accept, X-Mashape-Authorization',
                    'X-CSRF-TOKEN': this.csrf_token
                }
            }
        }
    },
    methods:{
        change(){
            if (this.$refs.myVueDropzone.dropzone.files.length > 0){
                this.showClear = true
            }else{
                this.showClear = false
            }
        },
        filesExceeded(file){
            this.$refs.myVueDropzone.removeAllFiles()
            this.$refs.myVueDropzone.addFile(file)
        },
        clearFiles(){
            this.$refs.myVueDropzone.removeAllFiles()
            this.showClear = false
        },
        toggleModal(){
            $('#formModal').modal('toggle')
        },
        updateForm(){
            this.sending = true

            var data = new FormData();
            data.append('application_type',this.form.application_type)
            data.append('version',this.form.version)
            data.append('environment',this.mode)

            if (this.$refs.myVueDropzone.dropzone.files[0]){
                data.append('file',this.$refs.myVueDropzone.dropzone.files[0])
            }else {
                data.append('file',null)
            }

            let element = document.getElementById('dropzone')

            element.style.pointerEvents = "none";

            axios.post(this.route,data,{
                headers: {
                    'X-Requested-With':'XMLHttpRequest',
                    'Access-Control-Allow-Headers' : 'Authorization, Content-Type, Accept, X-Mashape-Authorization',
                    'X-CSRF-TOKEN': this.csrf_token
                }
            })
                .then((response)=>{
                    element.style.pointerEvents = "all";
                    this.sending = false
                   this.clearForm()
                   this.toggleModal()
                    this.$eventBus.$emit('register-successful',response.data.message);
                    this.showSuccessAlert = true
                    this.message = response.data.message
                    setTimeout(() =>{
                        this.showSuccessAlert = false
                    }, 5000);
                })
                .catch(error => {
                    if (error.response) {
                        element.style.pointerEvents = "all";
                        this.serverError = error.message
                        this.sending = false
                    } else {
                        element.style.pointerEvents = "all";
                        this.serverError = error.message
                        this.sending = false
                    }


                    if (error.response.status < 500){
                        element.style.pointerEvents = "all";
                        this.errors.record(error.response.data)

                        if (error.response.data.errors.file){
                            this.$refs.myVueDropzone.removeAllFiles()
                        }
                        this.sending = false

                    }

                    setTimeout(() =>{
                        this.serverError = null
                    }, 5000);

                });
        },
        clearForm(){
            this.errors.clear()
            this.showClear = false
            this.form.version =''
            this.form.application_type = null
            this.$refs.myVueDropzone.removeAllFiles()
        },
        errorUploading (file, message, xhr) {

            if(message === 'You can not upload any more files.'){
                this.errorsMore = 'No puedes subir más archivos.'
            }else{
                this.errorsMore = null
                this.errors.record(message)
            }


            if (message.errors){
                if (message.errors.file){
                    $('.dz-error-message span').text(message.errors.file);
                } {
                    $('.dz-error-message span').text('Verifica la información enviada.');
                }
            }

        }
    }
}
</script>

<style scoped>

</style>
