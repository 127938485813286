<template>
  <div>
    <button
      type="button"
      class="btn btn-outline-avon  btn-sm"
      data-toggle="modal"
      @click="toggleModal"
    >
      Forzar desbloqueo
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      :id="'uploadModal_' + this.order.id"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Forzar desbloqueo</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert" v-if="serverError">
              <strong v-text="serverError"></strong>
            </div>
            <p>
              Se forzara el desbloqueo de la orden
              <strong>{{ data.external_id }}</strong>
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-avon "
              data-dismiss="modal"
              :id="'lockCancel_' + this.order.id"
            >
              Cancelar
            </button>
            <button
              type="button"
              @click="forceSend()"
              class="btn btn-avon"
              :id="'lockSubmit_' + this.order.id"
            >
              Forzar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["order", "csrf_token", "route"],
  data() {
    return {
      serverError: null,
      data: {
        id: this.order.id,
        external_id: this.order.external_order_id,
      },
    };
  },

  methods: {
    toggleModal() {
      $("#uploadModal_" + this.order.id).modal("toggle");
    },

    forceSend() {
      let element = document.getElementById("lockSubmit_" + this.order.id);
      let cancelBTN = document.getElementById("lockCancel_" + this.order.id);
      const row = document.getElementById("row_" + this.order.id);

      const eoi = document.getElementById("eoi_" + this.order.id);
      const fc = document.getElementById("fc_" + this.order.id);
      const zo = document.getElementById("zo_" + this.order.id);
      const ac = document.getElementById("ac_" + this.order.id);
      const us = document.getElementById("us_" + this.order.id);
      const ur = document.getElementById("ur_" + this.order.id);
      const re = document.getElementById("re_" + this.order.id);

      element.disabled = true;
      cancelBTN.disabled = true;

      axios
        .post(this.route, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Headers":
              "Authorization, Content-Type, Accept, X-Mashape-Authorization",
            "X-CSRF-TOKEN": this.csrf_token,
          },
        })
        .then((response) => {
          this.toggleModal();
          element.disabled = false;
          cancelBTN.disabled = false;
          eoi.innerHTML = response.data.order.external_order_id;
          fc.innerHTML = response.data.order.full_campaign;
          zo.innerHTML = response.data.order.zone;
          ac.innerHTML = response.data.order.account;
          us.innerHTML = response.data.unblock_status ? "Éxitoso" : "Fallido";
          ur.innerHTML = response.data.message;
          re.innerHTML = "";

          row.classList.add("color");
          setTimeout(() => {
            row.classList.remove("color");
          }, 5000);
        })
        .catch((error) => {
          if (error.response) {
            element.disabled = false;
            cancelBTN.disabled = false;
            this.serverError = error.message;
          } else {
            element.disabled = false;
            cancelBTN.disabled = false;
            this.serverError = error.message;
          }

          setTimeout(() => {
            this.serverError = null;
          }, 7000);
        });
    },
  },
};
</script>

<style>
</style>