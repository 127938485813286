<template>
    <div>
        <div class="row mt-3" v-if="loading">
            <div class="col text-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Cargando...</span>
                </div>
                Cargando aplicaciones disponibles
            </div>
        </div>
        <div v-else>
            <div class="row mt-3" v-if="applications.length > 0">
                <div class="col-12 col-md-6" v-for="application in applications" :key="application.version">
                    <a :href="application.url" class="text-avon">
                        <i :class="application.icon" aria-hidden="true"></i>
                        <span v-text="application.application_type"></span>
                    </a> -
                    <span v-text="application.upload_at" data-toggle="tooltip" data-placement="top" :title="application.readable_upload_at"></span>
                </div>
            </div>
            <div class="col-12 text-center" v-else>
                <span class="text-danger text-uppercase">No se han encontrado versiones de aplicaciones.</span>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: "ApplicationList",
    props:['route','user'],
    data(){
        return{
            loading: false,
            applications: {}
        }
    },
    methods:{
        fetchApplications(){
            this.loading=true;
            axios.get(this.route,{
                params:{
                    id:this.user
                }
            })
                .then((response)=>{
                    this.applications = response.data.data;
                    this.loading=false;
                })
                .catch((error)=>{
                    console.log('error');
                    this.loading=false;
                })
        }
    },
    mounted(){
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
        this.fetchApplications()
    },
    created() {
        this.$eventBus.$on('register-successful',this.fetchApplications)
    }
}
</script>

<style scoped>

</style>
